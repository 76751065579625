import $ from "jquery";
import "what-input";
import libs from "./lib/dependencies.js";

window.$ = $;
window.jQuery = $;
window.libs = libs;

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import 'foundation-sites'

//Pixmill Scripts
import { ready } from "./px/utils.js";

//import "./px/hamburger.js";
import "./px/opaque.js";
//import "./px/form.js";
//import "./px/lazyload.js";
import "./px/scrollreveal.js";
//import "./px/swiper.js";
//import "./px/offcanvas.js";
//import "./px/scrollToTermin.js";
//import "./px/customreveal.js";
//import "./px/desktopmenu.js";
import "./px/formfields.js";
import "./px/forminfoabend.js";
import "./px/offcanvasmenu.js";
import "./px/referenzlist.js";
import "./px/vorgehen.js";
import "./px/accordion.js";
import "./px/scrolltohash.js";

/*
import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";

ready(function () {
  addFunctionalityToProgressPath("progress-wrap");
});
*/

//import "./lib/foundation-explicit-pieces.js";
//$(document).foundation();